/* eslint-disable @typescript-eslint/no-explicit-any */

import { ILayoutBase, ILink } from '@lamesarv-sdk/types';

// -- Layout Utilities

export enum LayoutMenuPosition {
  main = 'main',
  footer = 'footer',
  generic = 'generic',
}

export interface ILayoutMenu {
  name: string;
  label: string;
  position: LayoutMenuPosition;
  items: ILink[];
}

export interface ILayoutSocialIcon extends ILink {
  type: string;
}

// -- Layout

export interface ILayoutHeader {
  logoUrl: string;
  menu: ILayoutMenu;
}

export interface ILayoutFooter {
  logoUrl: string;
  header: string;
  socialIcons: ILayoutSocialIcon[];
  menus: ILayoutMenu[];
  phone: string;
}

export interface ILayout extends ILayoutBase {
  header: ILayoutHeader;
  footer: ILayoutFooter;
  isPreview?: boolean;
}

// -- Context

export interface ILayoutContextData {
  modalOpen?: string;
  debug?: boolean;
}

export enum LayoutReducerActionType {
  updateModalOpen = 'updateModalOpen',
  clear = 'clear',
}

export interface ILayoutReducerAction {
  type: LayoutReducerActionType;
  payload?: any;
}
